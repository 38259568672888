body {
    margin: 0;
    background-color: #000;
    font-family: 'quicksand', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    box-sizing: border-box;
  }

 
.ProjectCard {
  // width: 600px;
  width: 46%;
  height: 500px;
  padding-bottom: 20px;
  box-shadow: 0 0 15px rgba(234, 81, 132, 0.4);
  border-radius: 15px;
  display: flex;
  justify-content: start;
  // align-items: center;
  flex-direction: column;
}

.imageContainer {
  // height: 320px;
  height: 60%;
  width: 100%;
  overflow: hidden;
  position: relative;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.imageContainer img {
  // height: 100%;
  width: 100%;
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  object-fit: cover;
}

.text {
  color: #ffffff;
  margin: 20px;
}

.buttons {
  margin: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media(min-width: 992px) and (max-width: 1199px) {
  .ProjectCard {
    height: 700px;
  }

  .imageContainer {
    height: 70%;
  }
}

@media(min-width: 0px) and (max-width: 1199px) {
  .ProjectCard {
    width: 100%;
  }
}

@media(min-width: 730px) and (max-width: 991px) {
  .ProjectCard {
    height: 600px;
  }
}

@media(min-width: 550px) and (max-width: 730px) {
  .ProjectCard {
    // height: 400px;
  }
}

@media(min-width: 380px) and (max-width: 551px) {
  .ProjectCard {
    height: 400px;
  }
}

@media(min-width: 0) and (max-width: 380px) {
  .ProjectCard {
    height: 350px;
  }
}
.projects {
  margin: 0 50px;
  padding-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
}

.heading h4 {
  color: #fff;
  font-size: 36px;
}

.heading h4 span {
  color: #41bcc5;
}

.cards {
  margin: 16px 0;
}

.projectsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: space-between;
  align-items: center;
}

@media(min-width: 992px) and (max-width: 1199px){
}

@media(min-width: 768px) and (max-width: 991px){
}

@media(min-width: 0px) and (max-width: 767px){
  .projects {
    margin: 30px;
  }
}

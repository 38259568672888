.circle {
  width: 30px;
  height: 30px;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid #fff;
  position: relative;
  margin: 0 10px;
  cursor: pointer;
}

.circle:hover {
  background-color: #ea5184;
  border: 2px solid #ea5184;
}

.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 15px;
  height: 15px;
}

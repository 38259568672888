@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap");

.home {
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 50px 0;
}

.textContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: "Josefin Sans", cursive;
  margin-bottom: 50px;
}

.textContainer p {
  font-size: 2.5rem;
  color: #fff;
}

.textContainer h3 {
  color: #ffffff;
  font-size: 4.375rem;
  margin: 0;
}

.nameHr {
  width: 450px;
  height: 5px;
  background-color: #41bcc5;
  border: none;
  margin: 0;
}

.socials {
  display: flex;
  align-items: center;
  justify-content: start;
}

.shapeContainer {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shape {
  //   width: 50%;
  height: 18rem;
  width: 18rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  transform: rotate(-30deg);
  position: relative;
}

.boxContainer {
  display: flex;
  gap: 0.75rem;
  transform: translateX(2rem);
}

.box {
  width: 8rem;
  height: 8rem;
  background-color: #ea5184;
  border-radius: 10px;
}

.glow {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  z-index: -10;
  width: 0px;
  height: 0px;
  border-radius: 50%;
  box-shadow: 0 0 200px 130px #c026d3;
}

.imgContainer {
  width: 500px;
  height: 500px;
  background-color: transparent;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
}

.imgContainer img {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.arrowDown {
  position: absolute;
  left: 50%;
  bottom: 15%;
  transform: translate(-50%, -15%);
  animation: bounce 1.5s infinite alternate;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(20px);
  }
}

@media(min-width: 481px) and (max-width: 767px) {
  .home {
    margin: 60px 30px 30px;
  }

  .textContainer p {
    font-size: 1.8rem;
  }

  .textContainer h3 {
    font-size: 4rem;
  }

  .nameHr {
    width: 400px;
  }
}

@media(max-width: 480px) {

  .home {
    // height: auto;

    margin: 60px 30px 30px;
  }

  .textContainer {
    margin: 50px 0;
  }

  .textContainer p {
    font-size: 1.3rem;
    margin-bottom: 25px;

  }

  .textContainer h3 {
    font-size: 2.8rem;
  }

  .nameHr {
    width: 280px;
    margin-bottom: 25px;

  }
}
.about {
  margin: 0 50px;
  padding-top: 100px;
  color: #d3d3d3;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.about .heading h2 {
  font-size: 36px;
  color: #fff;
}

.about h2 span {
  color: #41bcc5;
}

.content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageContainer {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imgContainer {
  width: 300px;
  height: 400px;
  border: 4px solid #ea5184;
  position: relative;
  overflow: hidden;
}

.imgContainer img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.textContainer {
  width: 60%;
}

.text {
  width: 85%;
}

.text p {
  color: #d3d3d3;
  line-height: 180%;
  letter-spacing: 0.5px;
  font-size: 1rem;
  text-align: justify;
}

.allBasicInformation {
  width: 85%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.basicInformation {
  display: flex;
  align-items: center;
  justify-content: start;
}

.basicInformation h4 {
  //color: #fff;
  font-weight: bold;
  margin-right: 10px;
}

.basicInformation p {
  font-size: 0.90625rem;
}

.button {
  margin-top: 20px;
}

button {
  margin: 0;
}

@media(min-width: 992px) and (max-width: 1199px){
  .imgContainer {
    width: 100%;
    margin: 20px;
    height: 400px;
  }
  
  .text {
    width: auto;
    padding-left: 20px;
  }

  .allBasicInformation {
    padding-left: 20px;
      width: auto;
  }
  
  .button {
    padding-left: 20px;
  }

}

@media(min-width: 768px) and (max-width: 991px){
  .content {
    flex-direction: column;
  }

  .imageContainer {
    width: 100%;
    margin: 40px 0;
  }

  .imgContainer {
    width: 400px;
    height: 400px;
  }

  .textContainer {
    width: 100%;
  }

  .text {
    width: auto;
  }

  .allBasicInformation {
    width: auto;
  }
 }

 @media(min-width: 0px) and (max-width: 767px){
  .about {
    margin: 30px;
  }

  .content {
    flex-direction: column;
  }

  .imageContainer {
    width: 100%;
    margin: 40px 0;
  }

  .imgContainer {
    width: 400px;
    height: 400px;
  }

  .textContainer {
    width: 100%;
  }

  .text {
    width: auto;
  }

  .allBasicInformation {
    width: auto;
    flex-direction: column;
    align-items: start;
  }
 }




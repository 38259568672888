.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 20px 100px;
  padding: 20px 50px;
  background-color: #000;
  width: 100%;
  z-index: 999;
  box-shadow: 0 0 15px rgba(234, 81, 132, 0.4);
  box-sizing: border-box;
  position: fixed;
  top: 0;
}

// .fixed {
//   position: fixed;
//   top: 0;
//   right: 0;
// }

.logo {
  color: #ea5184;
  letter-spacing: 2px;
  font-weight: bold;
  font-size: 30px;
}

.logo span {
  color: #fff;
}

ul {
  list-style-type: none;
}

.navlinks {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navlinks li {
  margin: 0 20px;
  font-weight: bold;
}

.contactLink {
  display: none;
}

.hamburger {
  display: none;
}

.links {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobileLinks {
  display: none;
}

.button {
  margin: 0 10px;
}

a {
  text-decoration: none;
  color: #fff;
}

@media(max-width: 991px){
  .header {
    // box-shadow: 0 0 15px rgba(234, 81, 132, 0);
    // position: relative;
    // padding: 20px 60px;
  }

  .hamburger {
    display: block;
    float: right;
  }

  .mobileLinks {
    align-items: center;
    background-color: #000;
    display: flex;
    height: -webkit-fill-available;
    justify-content: center;
    left: -100%;
    position: fixed;
    text-align: center;
    top: 0;
    transition: all 0.3s ease-in-out;
    width: 100%;
    z-index: 300;
  }

  .active {
    left: 0;
  }

  .navlinks li {
    margin: 20px;
  }

  a {
    font-size: 1.2rem;
  }

  .links {
    display: none;
    // flex-direction: column;
  }

  .navlinks {
    flex-direction: column;
    padding: 0;
  }
}

@media(max-width: 767px){
  .header {
    padding: 20px 30px;
  }
}

@media(min-width: 481px) and (max-width: 767px){
  .textContent {
    padding: 40px;
  }
}

@media(max-width: 480px){
  .textContent {
    padding: 40px 20px;
  }
}

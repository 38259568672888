.button {
  padding: 12px 20px;
  letter-spacing: 2px;
  font-size: 16px;
  border-radius: 5px;
  width: 170px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.footer {
  margin: 60px 50px 20px 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footerContent {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer p {
  color: #fff;
  margin: 0 10px;
}

.footer p span {
  color: #ea5184;
}

.socials {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media(min-width: 768px) and (max-width: 991px) {
  .footerContent {
    width: 100%;
  }
}

@media(min-width: 0px) and (max-width: 767px) {
  .footer {
    margin: 60px 30px 50px;
  }

  .footerContent {
    width: 100%;
    justify-content: center;
    flex-direction: column;
  }

  .socials {
    margin: 20px 0;
  }
}
.skills {
  margin: 0 50px;
  padding-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.heading h4 {
  color: #fff;
  font-size: 36px;
}

.heading h4 span {
  color: #41bcc5;
}

.skill {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 100px;
}

@media(min-width: 992px) and (max-width: 1199px){
  .skill {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media(min-width: 768px) and (max-width: 991px){
  .skill {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media(min-width: 481px) and (max-width: 767px){
  .skills {
    margin: 30px;
  }

  .skill {
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
  }
}

@media(max-width: 480px){
  .skills {
    margin: 30px;
  }

  .skill {
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
  }
}

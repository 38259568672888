@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap");

.contact {
  margin: 0 50px;
  padding-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.heading h4 {
  color: #fff;
  font-size: 36px;
}

.heading h4 span {
  color: #41bcc5;
}

.label {
  color: #fff;
  font-size: 20px;
  letter-spacing: 0.5px;
  font-family: "Josefin Sans", cursive;
  margin-bottom: 1.25rem;
}

input {
  width: 400px;
  height: 40px;
  border-radius: 0.5rem;
  border: none;
  font-size: 18px;
  padding: 5px 10px;
  font-family: "Josefin Sans", cursive;
}

input:focus {
  // border: #41bcc5;
  border: #ea5184;
}

.box {
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  margin-top: 30px;
}

textarea {
  width: 400px;
  border-radius: 0.5rem;
  border: none;
  font-size: 18px;
  padding: 5px 10px;
  font-family: "Josefin Sans", cursive;
}

.button {
  margin-top: 40px;
}

@media(min-width: 481px) and (max-width: 767px){
  .contact {
    margin: 30px;
  }
}

@media(max-width: 480px){
  .contact {
    margin: 30px;
  }

  .container {
    width: 100%;
  }

  .box {
    width: 100%;
  }

  input {
    width: 100%;
    box-sizing: border-box;
  }

  textarea{
    width: 100%;
    box-sizing: border-box;
  }
}

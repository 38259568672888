.skill {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.image {
    width: 100px;
    height: 100px;
}

.image img{
    width: 100%;
    height: 100%;
}

.name {
    color: #D3D3D3;
    font-size: 1.75rem;
}

@media(min-width: 481px) and (max-width: 767px){
    .image {
        width: 80px;
        height: 80px;
    }
}

@media(max-width: 480px){
    .image {
        width: 100px;
        height: 100px;
    }
}
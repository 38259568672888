.servicesMenu {
  //height: 100vh;
  margin: 0 50px;
  padding-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.heading h4 {
  color: #fff;
  font-size: 36px;
}

.heading h4 span {
  color: #41bcc5;
}

.text {
  width: 60%;
  text-align: center;
}

.text p {
  color: #d3d3d3;
  line-height: 180%;
  letter-spacing: 0.5px;
  font-size: 16px;
}

.topServiceContainers {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  flex-wrap: wrap;
}

.bottomServiceContainers {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

hr {
  border: 3px solid #41bcc5;
  border-radius: 10px;
  width: 100px;
}

@media(min-width: 992px) and (max-width: 1199px){
  .text {
    width: 80%;
  }
}

@media(min-width: 0) and (max-width: 991px){
  .text {
    width: 100%;
    text-align: justify;
  }
}

@media(min-width: 0px) and (max-width: 767px){
  .servicesMenu {
    margin: 30px;
  }}
